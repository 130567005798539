<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('brrdNew.title')}`"
      :backButton="true"
      @backToPage="moveToOther('BRRD')"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
            :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />

          <Input
            v-model="edited_item.date"
            :label="$t('general.date')"
            class="s-mb-20"
            type="date"
            :error="validation.date"
          />
          <Textarea
            v-model="edited_item.description"
            :label="$t('general.content')"
            class="s-mb-20"
            :error="validation.description"
          />

          <Input
            v-model="edited_item.youtube_link"
            :label="$t('general.youtubeLink')"
            class="s-mb-20"
          />

          <div class="s-pb-30 s-position-relative">
            <div class="s-mb-20">
              <label class="s-input-label">{{ $t("general.topics") }}</label>
              <Multiselect
                v-model="edited_item.expertise_detail"
                tag-placeholder="Add this as new topic"
                placeholder="Search or add a topic"
                :options="expertise_dropdown.map((type) => type.id)"
                :custom-label="
                  (opt) => expertise_dropdown.find((x) => x.id == opt).name
                "
                :multiple="true"
              >
              </Multiselect>
              <span class="s-input-error" v-if="validation.expertise_detail">
                {{ $t("general.fillThisField") }}
              </span>
            </div>

            <p class="s-text-primary s-weight-600">
              {{ $t("general.uploadImage") }}
            </p>
            <div v-if="inputType == 'Edit'">
              <img
                :src="brrd_data.thumbnail"
                alt=""
                style="width: 100px; height: 100px; object-fit: contain"
              />
            </div>
            <div class="s-pb-30 s-position-relative">
              <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
                <div>
                  <img
                    :src="require('@/assets/icons/primary/cloud_upload.png')"
                  />
                  <p>{{ $t("general.dragAndDrop") }}</p>
                  <p>{{ $t("general.or") }}</p>
                  <Button>{{ $t("general.uploadFile") }}</Button>
                </div>
              </div>
              <input
                v-if="!fileDoc.name"
                id="document"
                ref="document"
                class="s-hide"
                type="file"
                @change="importData('document')"
                accept="image/png, image/jpg, image/jpeg"
                multiple
              />
              <div class="s-position-relative s-fullWidth s-center-flex">
                <div
                  class="
                    s-center-flex
                    s-mb-16
                    s-bg-green-40
                    s-plr-10
                    s-radius-10
                    s-fullWidth
                  "
                  v-if="fileDoc.name"
                >
                  <p class="s-text-primary">{{ fileDoc.name }}</p>
                  <img
                    :src="require('@/assets/icons/close-primary.svg')"
                    class="s-ml-auto"
                    @click="fileDoc = {}"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button class="s-ml-auto" color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [form, common],
  props: ["inputType"],
  data: () => ({
    edited_item: {
      title: "",
      date: "",
      description: "",
      expertise_detail: "",
      youtube_link: "",
    },

    validation: {
      title: false,
      date: false,
      description: false,
      expertise_detail: false,
      youtube_link: false,
    },

    fileDoc: {},
    value: [],
    isLoading: false,
    //empty_field: false,
  }),
  computed: {
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      brrd_data: "content/getContent",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    title() {
      return this.edited_item.title == "" ? true : false;
    },
  },
  mounted() {
    this.initData();
    this.getEditedData();
  },
  methods: {
    ...mapActions({
      content: "content/postContentData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setContentSingleData: "content/setContentSingleData",
      content_update: "content/updateContentData",
    }),

    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      this.setLoadingPage(false);
    },

    async getEditedData() {
      if (this.inputType == "Edit") {
        await this.setContentSingleData(this.$route.params.slug);
        //traverse through object's keys

        for (const key in this.edited_item) {
          this.edited_item[key] = this.brrd_data[key];
        }

        if (this.edited_item.youtube_link != null) {
          this.edited_item.youtube_link = `https://www.youtube.com/embed/${this.edited_item.youtube_link}`;
        } else {
          this.edited_item.youtube_link = "";
        }

        //get id experise
        let expertise_temp = new Array();
        this.brrd_data.expertise_detail.forEach((element) => {
          expertise_temp.push(element.id);
        });
        this.edited_item.expertise_detail = Object.assign([], expertise_temp);
        //this.fileDoc.name = this.brrd_data.name
      }
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        //Ambil id dari link youtube
        var youtube_id = "";
        if (
          this.edited_item.youtube_link != "" ||
          this.edited_item.youtube_link != null
        ) {
          youtube_id = this.generateVideoId(this.edited_item.youtube_link);
        }

        let formData = new FormData();
        formData.append("title", this.edited_item.title);
        formData.append("description", this.edited_item.description);
        formData.append("date", this.edited_item.date);
        formData.append("thumbnail", this.fileDoc);
        if (youtube_id != null) {
          formData.append("youtube_link", youtube_id);
        }

        for (var i = 0; i < this.edited_item.expertise_detail.length; i++) {
          formData.append(
            "expertise_detail_id[]",
            this.edited_item.expertise_detail[i]
          );
        }
        if (this.inputType == "New") {
          await this.content(formData);
        } else {
          formData.append("id", this.brrd_data.id);
          await this.content_update(formData);
        }

        if (this.$store.getters["content/getStatus"] == 1) {
          this.$router.push(this.$translate({ name: "BRRD" }));
        } else {
          x;
          this.showSnackbar({
            type: "error",
            text: "general.addEditFailed",
          });
        }
      }
    },

    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },
    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "BRRD",
        })
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
